<template>
  <div class="crud form main-view">
    <el-form :model="form" label-width="120px" label-position="top" ref="form" size="small"
      v-loading="saving || loading" v-if="status === null">
      <h2>{{ title }}</h2>
      <el-form-item :label="$t('contact-me.chat-center.social-pages.facebook-account')" required prop="metaAccount"
        class="w-full" v-if="this.id === 0">
        <el-select v-model="form.metaAccount" filterable reserve-keyword
          placeholder="Select a Facebook user account" class="w-full" :loading="fetchValues" @change="findPages">
          <el-option v-for="item in accounts" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <div v-else class="flex">
        <p class="title">{{ $t('contact-me.chat-center.social-pages.facebook-account') }} </p>
        <p>{{ this.entity.metaAccount.user.name + " " + this.entity.metaAccount.user.lastname }}</p>
      </div>
      <el-form-item :label="$t('contact-me.chat-center.social-pages.facebook-page')" required prop="pageId"
        class="w-full" v-if="this.id === 0">
        <el-select v-model="form.pageId" filterable remote reserve-keyword placeholder="Select a Facebook user account"
          class="w-full">
          <el-option v-for="item in pages" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
        <p class="help" >{{ $t('contact-me.chat-center.social-pages.start-typing-to-search-please-enter-at-least-3-characters-to-see-results') }}</p>
      </el-form-item>
      <div v-else class="flex">
        <p class="title">{{ $t('contact-me.chat-center.social-pages.facebook-page') }}</p>
        <p>{{ this.entity.pageName }}</p>
      </div>
      <el-form-item :label="$t('contact-me.chat-center.social-pages.destination')" required prop="destination">
        <el-select v-model="form.destination" filterable remote reserve-keyword
          placeholder="Search a time condition or department" class="w-full" :remote-method="findDestination"
          :loading="fetchValues">
          <el-option-group v-for="group in destinations" :key="group.label" :label="group.label">
            <el-option v-for="item in group.options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-option-group>
        </el-select>
        <p class="help">{{ $t('contact-me.chat-center.social-pages.start-typing-to-search-please-enter-at-least-3-characters-to-see-results') }}</p>
      </el-form-item>
      <el-button size="small" @click="$router.go(-1)">{{ $t("common.cancel") }}</el-button>
      <el-button type="primary" size="small" @click="submit">{{ $t("common.save") }}</el-button>
    </el-form>
    <div class="message" v-else>
      <div>
        <img src="@/img/success.svg" v-if="status === 200" />
        <img src="@/img/error.svg" v-else />
        <p> {{ message }} </p>
        <div class="buttons">
          <el-button type="text" @click="$router.go(-1)">{{ $t("common.go-back-to-the-list") }}</el-button>
          <el-button type="text" @click="status = null" v-if="status !== 404">{{
            $t("common.show-the-form")}}</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/api/http'
export default {
  props: {
    entity: {
      required: false,
    },
    id: {
      required: false,
      default: 0
    },
    account: {
      required: false,
      default: null
    }
  },
  data () {
    let accounts = []
    let pageId = null
    let pageName = null
    let destination = null
    let metaAccount = null

    if (this.id) {
      pageId = this.entity.pageId
      pageName = this.entity.pageName
      metaAccount = this.entity.metaAccount['@id']
      destination = this.entity.timeCondition ? this.entity.timeCondition : this.entity.department
    }

    if (this.account) {
      metaAccount = `/api/meta_accounts/${this.account['id']}`
      accounts.push({
        value: metaAccount,
        label: this.account.user.name + " " + this.account.user.lastname
      })
    }

    return {
      destination: destination ? destination : null,
      store: 'socialPages',
      status: null,
      saving: false,
      loading: false,
      fetchValues: false,
      queryTimeout: null,
      form: {
        metaAccount,
        pageId,
        pageName,
        destination: destination ? destination['@id'] : null
      },
      accounts,
      pages: []
    }
  },
  methods: {
    findDestination (query) {
      if (query.length >= 3) {
        this.fetchValues = true
        clearTimeout(this.queryTimeout);
        this.queryTimeout = setTimeout(async () => {
          const params = { ["organization.id"]: this.organizationId, name: query }
          await this.$store.dispatch('timeConditions/all', params)
          await this.$store.dispatch('departments/all', params)
          this.fetchValues = false
        }, 300);
      }
    },
    async findPages () {
      const id = this.form.metaAccount.replace('/api/meta_accounts/', '')
      const { data } = await http.get(`/meta/account/${id}/pages`)
      this.pages = data.map(page => ({ value: page.id, label: page.name }))
    },
    submit () {
      this.$refs.form.validate(async (isValid) => {
        if (!isValid) {
          return
        }

        this.saving = true
        const data = { ...this.form }
        data.timeCondition = !/departments/.test(data.destination) ? data.destination : null
        data.department = /departments/.test(data.destination) ? data.destination : null

        if (this.id === 0) {
          data.pageName = this.pages.find(p => p.value === data.pageId).label
        }

        delete data.destination

        try {
          if (this.id === 0) {
            await this.$store.dispatch(this.store + '/create', data)
          } else {
            await this.$store.dispatch(this.store + '/update', { id: this.id, data })
          }
          this.status = 200
        } catch (error) {
          this.status = error.response.status
        } finally {
          this.saving = false
        }
      })
    },
    async fetchPages (values) {
      this.fetchValues = true
      const params = { ...values, ["organization.id"]: this.organizationId, pagination: 0 }
      const { data } = await http.get('meta_accounts', { params })
      this.accounts = data['hydra:member'].map((account) => {
        return {
          value: account['@id'],
          label: account.user.name + " " + account.user.lastname
        }
      })
      this.fetchValues = false
    }
  },
  computed: {
    organizationId () {
      return this.$store.getters["contactMe/getOrganizationId"];
    },
    title () {
      return this.id ? this.$t('contact-me.chat-center.social-pages.edit-page') : this.$t('contact-me.chat-center.social-pages.new-page')
    },
    destinations () {
      const deptsOptions = this.$store.getters['departments/getData'] ? this.$store.getters['departments/getData'].map(opt => ({ value: opt['@id'], label: opt.name })) : []
      const tcOptions = this.$store.getters['timeConditions/getData'] ? this.$store.getters['timeConditions/getData'].map(opt => ({ value: opt['@id'], label: opt.name })) : []

      if (this.id && this.entity) {
        if (/departments/.test(this.destination['@id'])) {
          deptsOptions.push({ value: this.destination['@id'], label: this.destination.name })
        } else {
          tcOptions.push({ value: this.destination['@id'], label: this.destination.name })
        }
      }

      return [
        {
          label: 'Time Conditions',
          options: tcOptions.filter((obj, index, self) => index === self.findIndex((o) => o.value === obj.value))
        },
        {
          label: 'Departments',
          options: deptsOptions.filter((obj, index, self) => index === self.findIndex((o) => o.value === obj.value))
        }
      ]
    },
    message () {
      if (this.status === 200) {
        return this.$t("common.saved")
      }

      if (this.status === 404) {
        return this.$t("common.not-found")
      }

      return this.$t("common.not-saved")
    }
  },
  created () {
    this.fetchPages({})

    if (this.form.metaAccount && this.id === 0) {
      this.findPages()
    }
  }
}
</script>

<style scoped>
.flex {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}


.w-full {
  width: 100%;
}

.gap-4 {
  gap: 1rem;
}

.gap-8 {
  gap: 2rem;
}

.title {
  font-family: 'Nunito', sans-serif;
  font-weight: bold;
  color: #5A5B5B
}

.buttons,
.message {
  width: 100%;
  display: flex;
  justify-content: center;
}

.message p {
  text-align: center;
}

.help {
  font-size: 12px;
  color: var(--blue-grey);
}
</style>